<app-avatar-en></app-avatar-en>

<section class="section is-small is-primary has-text-centered">
  <div class="container is-small">
    <div class="columns is-centered">
      <div class="column is-three-fifths">
        <h1 class="title is-spaced is-size-3-desktop is-size-4-mobile">About me...</h1>
        <h2 class="subtitle is-size-5-desktop">I am Alexis Toullat, a passionate Agile Coach with extensive experience in Agile methodologies, SAFe, and Scrum. My career journey has been dedicated to nurturing collaboration and unity among diverse teams, driving operational efficiencies, and developing impactful software solutions. With a robust background in R&D engineering and process improvement, I bring a unique blend of technical expertise and Agile leadership to every project.</h2>
        <a class="button is-outlined is-rounded" href="assets/CV Alexis Toullat Agile Coach.pdf">My résumé in PDF</a>
      </div>
    </div>
  </div>
</section>
<section class="section skills is-small has-text-centered">
  <div class="container is-large">
    <div class="box">
      <div class="content">
        <div class="columns is-centered">
          <div class="column">
            <figure class="image"><i class="fas fa-rocket fa-2x"></i></figure>
            <!-- <figure class="image"><img class="is-skill-icon" src="img/icons/designer.svg"></figure> -->
            <h1 class="title is-size-4 is-spaced">SAFe and Scrum</h1>
            <p>Certified in SAFe® 6 Agilist and Professional Scrum Master.</p>
            <p class="list-title has-text-primary has-text-weight-normal">I specialize in:</p>
            <ul>
              <li>Guiding teams to adopt Agile methodologies like SAFe and Scrum.</li>
              <li>Enhancing collaboration and communication for faster, high-quality deliveries.</li>
              <li>Implementing Agile practices that drive efficiency and innovation.</li>
            </ul>
            <p class="list-title has-text-primary has-text-weight-normal">By integrating SAFe and Scrum, I can help your business streamline processes, foster a culture of continuous improvement, and achieve higher effectiveness.</p>
          </div>
          <div class="column">
            <figure class="image"><i class="fas fa-arrows-spin fa-2x"></i></figure>
            <!-- <figure class="image"><img class="is-skill-icon" src="img/icons/frontend.svg"></figure> -->
            <h1 class="title is-size-4 is-spaced">Continuous Improvement</h1>
            <p>In my role as a PPI Process Manager, I have consistently identified and addressed operational inefficiencies.</p>
            <p class="list-title has-text-primary has-text-weight-normal">I excel in:</p>
            <ul>
              <li>Implementing practical solutions that lead to measurable improvements.</li>
              <li>Coaching teams to embrace a mindset of continuous improvement.</li>
              <li>Using data-driven strategies to enhance productivity and reduce costs.</li>
            </ul>
            <p class="list-title has-text-primary has-text-weight-normal">My expertise in process improvement ensures that your Agile transformation is not just theoretical but results in tangible, sustainable enhancements in your operations.</p>
          </div>
          <div class="column">
            <figure class="image"><i class="fas fa-glasses fa-2x"></i></figure>
            <!-- <figure class="image"><img class="is-skill-icon" src="img/icons/mentor.svg"></figure> -->
            <h1 class="title is-size-4 is-spaced">Agile Coaching</h1>
            <p>I constantly draw on my experience as a R&D C++ Engineer and Agile Coach.</p>
            <p class="list-title has-text-primary has-text-weight-normal">It enables me to:</p>
            <ul>
              <li>Develop and optimize software solutions within an Agile framework.</li>
              <li>Lead teams through Agile practices to solve complex technical challenges.</li>
              <li>Create environments where teams thrive, innovate, and deliver exceptional results.</li>
            </ul>
            <p class="list-title has-text-primary has-text-weight-normal">Through tailored coaching sessions, I help teams build robust, high-performance solutions while maintaining Agile principles, ensuring your business stays competitive and innovative.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="section projects is-white has-text-centered">
  <div class="container is-narrow">
    <h1 class="title is-spaced is-size-3-desktop is-size-4-mobile">What is the Agile mindset?</h1>
    <h2 class="subtitle is-size-5-desktop">
      Want to discover how Agility can help your business?
    </h2>
    <h2 class="subtitle is-size-5-desktop is-spaced">
      I invite you to read <a href="https://agilemanifesto.org">the agile manifesto</a>.
    </h2>
  </div>
</section> -->
<section class="section projects is-narrow is-white has-text-centered">
  <div class="container is-narrow">
    <h1 class="title is-spaced is-size-3-desktop is-size-4-mobile">Projects</h1>
    <h2 class="subtitle is-size-5-desktop">Here are some projects I have collaborated on. If you want to learn more about how optimization and data science can help your business, feel free to send me an <a href="mailto:alexis.toullat@at-consulting.fr">email</a>.</h2>
    <div class="project-grid">
      <div class="columns is-multiline is-mobile">
        <div class="column is-12-mobile is-half-tablet is-one-third-desktop">
          <figure class="image is-3by2">
            <!-- <h1>EXAPTA</h1> -->
            <img class="project-thumb" style="object-fit: scale-down" src="../../../../assets/exapta_logo.png">
            <figcaption>
              <h1 class="title is-size-5 is-size-4-widescreen">Development and integration of a decision support tool for planning phytosanitary treatments in vineyards</h1><a class="button is-primary is-outlined is-rounded" href="https://www.process2wine.com/les-autres-modules-process2wine/exapta/" target="_blank"><span>Go to the site</span><span class="icon"><svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg><!-- <i class="fas fa-angle-right"></i> Font Awesome fontawesome.com --></span></a>
            </figcaption>
            <div class="overlay"></div>
          </figure>
        </div>
        <div class="column is-12-mobile is-half-tablet is-one-third-desktop">
          <figure class="image is-3by2"><img class="project-thumb" src="../../../../assets/logo_iof2020.svg">
            <figcaption>
              <h1 class="title is-size-5 is-size-4-widescreen">Development of an infrastructure to collect and generate added value from sensors placed in the vineyard</h1><a class="button is-primary is-outlined is-rounded" href="https://www.iof2020.eu" target="_blank"><span>Go to the site</span><span class="icon"><svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg><!-- <i class="fas fa-angle-right"></i> Font Awesome fontawesome.com --></span></a>
            </figcaption>
            <div class="overlay"></div>
          </figure>
        </div>
        <div class="column is-12-mobile is-half-tablet is-one-third-desktop">
          <figure class="image is-3by2"><img class="project-thumb" style="object-fit: scale-down" src="../../../../assets/TFS.jpeg">
            <figcaption>
              <h1 class="title is-size-5 is-size-4-widescreen">SAFe RTE for the development of Cryo-EM single particle analysis workflow</h1><a class="button is-primary is-outlined is-rounded" href="https://www.thermofisher.com/fr/fr/home/electron-microscopy/life-sciences/cryo-em.html" target="_blank"><span>Aller sur le site</span><span class="icon"><svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg><!-- <i class="fas fa-angle-right"></i> Font Awesome fontawesome.com --></span></a>
            </figcaption>
            <div class="overlay"></div>
          </figure>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section projects is-narrow is-white has-text-centered">
  <div class="container">
    <h1 class="title is-spaced is-size-3-desktop is-size-4-mobile">Conferences</h1>
    <h2 class="subtitle is-size-5-desktop">I enjoy attending conferences and meeting other passionate people. Here are some of the conferences I have attended.</h2>
    <div class="project-grid">
      <div class="columns is-multiline is-mobile is-bottom-spaced">
        <div class="column is-12-mobile is-half-tablet is-one-third-desktop">
          <figure class="image is-3by2"><img class="project-thumb" style="object-fit: scale-down" src="../../../../assets/logo_ismp_2018_bordeaux.png">
            <figcaption>
              <h1 class="title is-size-5 is-size-4-widescreen">ISMP 2018 in Bordeaux</h1><a class="button is-primary is-outlined is-rounded" href="https://ismp2018.sciencesconf.org" target="_blank"><span>Aller sur le site</span><span class="icon"><svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg><!-- <i class="fas fa-angle-right"></i> Font Awesome fontawesome.com --></span></a>
            </figcaption>
            <div class="overlay"></div>
          </figure>
        </div>
        <div class="column is-12-mobile is-half-tablet is-one-third-desktop">
          <figure class="image is-3by2"><img class="project-thumb" style="object-fit: scale-down" src="../../../../assets/logo_bordeaux_data_science.jpg">
            <figcaption>
              <h1 class="title is-size-5 is-size-4-widescreen">Dataquitaine 2020 in Bordeaux Kedge</h1><a class="button is-primary is-outlined is-rounded" href="http://www.dataquitaine.com/2020/conference-ia-ai-datascience-ro-bordeaux-2020" target="_blank"><span>Aller sur le site</span><span class="icon"><svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg><!-- <i class="fas fa-angle-right"></i> Font Awesome fontawesome.com --></span></a>
            </figcaption>
            <div class="overlay"></div>

          </figure>
        </div>
        <div class="column is-12-mobile is-half-tablet is-one-third-desktop">
          <figure class="image is-3by2"><img class="project-thumb" style="object-fit: scale-down" src="../../../../assets/favicon-atbdx.png">
            <figcaption>
              <h1 class="title is-size-5 is-size-4-widescreen">Agile Tour 2023 in Bordeaux</h1><a class="button is-primary is-outlined is-rounded" href="https://agiletourbordeaux.fr" target="_blank"><span>Aller sur le site</span><span class="icon"><svg class="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg><!-- <i class="fas fa-angle-right"></i> Font Awesome fontawesome.com --></span></a>
            </figcaption>
            <div class="overlay"></div>
          </figure>
        </div>
      </div>
    </div>
    <!-- <div class="columns is-centered"> -->
      <!-- <div class="column"><a class="button is-primary is-outlined is-rounded is-medium" href="https://dribbble.com/mattfarley"><span class="icon"><svg class="svg-inline--fa fa-dribbble fa-w-16" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="dribbble" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119.252 8 8 119.252 8 256s111.252 248 248 248 248-111.252 248-248S392.748 8 256 8zm163.97 114.366c29.503 36.046 47.369 81.957 47.835 131.955-6.984-1.477-77.018-15.682-147.502-6.818-5.752-14.041-11.181-26.393-18.617-41.614 78.321-31.977 113.818-77.482 118.284-83.523zM396.421 97.87c-3.81 5.427-35.697 48.286-111.021 76.519-34.712-63.776-73.185-116.168-79.04-124.008 67.176-16.193 137.966 1.27 190.061 47.489zm-230.48-33.25c5.585 7.659 43.438 60.116 78.537 122.509-99.087 26.313-186.36 25.934-195.834 25.809C62.38 147.205 106.678 92.573 165.941 64.62zM44.17 256.323c0-2.166.043-4.322.108-6.473 9.268.19 111.92 1.513 217.706-30.146 6.064 11.868 11.857 23.915 17.174 35.949-76.599 21.575-146.194 83.527-180.531 142.306C64.794 360.405 44.17 310.73 44.17 256.323zm81.807 167.113c22.127-45.233 82.178-103.622 167.579-132.756 29.74 77.283 42.039 142.053 45.189 160.638-68.112 29.013-150.015 21.053-212.768-27.882zm248.38 8.489c-2.171-12.886-13.446-74.897-41.152-151.033 66.38-10.626 124.7 6.768 131.947 9.055-9.442 58.941-43.273 109.844-90.795 141.978z"></path></svg><i class="fab fa-dribbble"></i> Font Awesome fontawesome.com</span><span>See more on Dribbble</span></a></div> -->
    <!-- </div> -->
  </div>
</section>

<section class="section call-to-action is-primary has-text-centered">
    <div class="container">
        <div class="box">
            <div class="columns level">
                <div class="column level-item">
                    <h1 class="title">Start a collaboration</h1>
                </div>
                <div class="column level-item">
                    <p>I am excited about the opportunity to bring my skills and experience to new and challenging projects.</p>
                    <br/>
                    <p>If you are looking for an Agile Coach who can drive transformation, foster collaboration, and deliver exceptional results, let's connect!</p>
                </div>
                <div class="column level-item"><a class="button is-primary is-outlined is-rounded is-medium" href="mailto:alexis.toullat@at-consulting.fr">Let's go!</a></div>
            </div>
        </div>
    </div>
</section>

<footer id="contact" class="section is-primary is-small has-text-centered">
  <div class="container"><a class="logo" href="/"></a>
      <div class="social-icons">
          <p class="field">
              <a class="button is-medium" href="https://twitter.com/ConsultingAtl" target="_blank">
                  <span class="icon is-large">
                      <svg class="svg-inline--fa fa-twitter fa-w-16 fa-fw" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                          <path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">

                          </path>
                      </svg><!-- <i class="fab fa-twitter fa-fw"></i> Font Awesome fontawesome.com -->
                  </span>
              </a>
              <a class="button is-medium" href="https://www.linkedin.com/in/alexis-toullat-05882882/" target="_blank">
                  <span class="icon is-large">
                      <svg class="svg-inline--fa fa-linkedin-in fa-w-14 fa-fw" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                          <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                          </path>
                      </svg><!-- <i class="fab fa-linkedin-in fa-fw"></i> Font Awesome fontawesome.com -->
                  </span>
              </a>
              <a class="button is-medium" href="mailto:alexis.toullat@at-consulting.fr" target="_blank">
                  <span class="icon is-large">
                      <svg class="svg-inline--fa fa-envelope fa-w-16 fa-fw" aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                          <path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z">
                          </path>
                      </svg><!-- <i class="far fa-envelope fa-fw"></i> Font Awesome fontawesome.com -->
                  </span>
              </a>
              <a class="button is-medium" href="https://github.com/ng-at33" target="_blank">
                  <span class="icon is-large">
                    <i class="fab fa-github"></i>
                  </span>
              </a>
          </p>
      </div>
      <div class="made-by-bulma"><a href="https://bulma.io"><img src="https://bulma.io/assets/brand/Bulma%20Icon%20White.png" alt="Made with Bulma" width="24" height="24"></a></div>
      <div class="made-by-bulma">&copy; Copyright Alexis Toullat. All Rights Reserved</div>
  </div>
</footer>